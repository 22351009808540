<template>
    <div>
        <div style="margin-bottom: 50px;">
            <van-image src="http://lf-public.oss-cn-shenzhen.aliyuncs.com/explore/buy3.png" style="display: block;"></van-image>
            <div style="font-size: 15px;position: relative;margin-top: -30px;">
                <div style="box-shadow: 0 8px 12px #ebedf0;border-radius:10px;background: #fff;margin: 10px;padding: 10px 14px;">
                    <div style="font-weight: bold;margin-top: 10px;display: flex;font-size: 15px;">
                        <van-image :src="assets[0]" height="15" width="10" style="margin: auto 10px auto 0"></van-image>
                        <span>客户到店消费没写点评怎么办</span>
                    </div>
                    <p>来飞网上活跃着大量的美团点评用户，他们看到店主发布的补写点评任务，会挑选曾经漏评的店铺补发点评</p>
                    <div style="font-weight: bold;margin-top: 10px;display: flex;font-size: 15px;">
                        <van-image :src="assets[0]" height="15" width="10" style="margin: auto 10px auto 0"></van-image>
                        <span>不刷单不炒作</span>
                    </div>
                    <p>召回的是曾经到店消费的客户补发点评。真实点评客观评价，让你的店铺在增加好评的同时，规避诚信度的风险</p>
                    <div style="font-weight: bold;margin-top: 10px;display: flex;font-size: 15px;">
                        <van-image :src="assets[0]" height="15" width="10" style="margin: auto 10px auto 0"></van-image>
                        <span>快速提升店铺星级</span>
                    </div>
                    <p>因为是补发点评，无需到店，点评上量快，可快速提升店铺星级</p>
                </div>
            </div>
            <div style="display: flex;justify-content: center;align-items: center;margin: 20px;">
                <van-image :src="assets[1]" style="flex: 0 0 auto;height: 3px;width: 70px;"></van-image>
                <span style="flex: 0 0 auto;margin: 0 10px;font-size: 20px;font-weight: bold;">活动流程</span>
                <van-image :src="assets[1]" style="flex:0 0 auto;height: 3px;width: 70px;transform: rotate(180deg);-o-transform: rotate(180deg);-webkit-transform: rotate(180deg);-moz-transform: rotate(180deg);filter:progid:DXImageTransform.Microsoft.BasicImage(Rotation=2);"></van-image>
            </div>
            <div class="advantage">
                <div>
                    <van-image :src="assets[2]"></van-image>
                    <p>店主发布点评补发任务</p>
                </div>
                <div>
                    <van-image :src="assets[3]"></van-image>
                    <p>来飞网根据店铺地址精准匹配曾经到店的用户</p>
                </div>
                <div>
                    <van-image :src="assets[4]"></van-image>
                    <p>用户接点评补发任务并补发点评</p>
                </div>
                <div>
                    <van-image :src="assets[5]"></van-image>
                    <p>店主验收点评内容，平台向用户结算佣金</p>
                </div>
            </div>
            <div style="height: 80px;width: 100%;"></div>
        </div>
        <van-goods-action>
            <van-goods-action-button style="border-radius: 999px;" type="danger" text="立即参与" @click="buy"/>
        </van-goods-action>
        <van-sku
                v-model="show"
                :sku="sku"
                :goods="goods"
                :hide-stock="true"
                :initial-sku="initialSku"
                :close-on-click-overlay="true"
                :show-add-cart-btn="false"
                @buy-clicked="onBuyClicked">
            <template slot="sku-header" slot-scope="props">
                <div></div>
            </template>
            <template slot="extra-sku-group" slot-scope="props">
                <van-cell title="服务单价">
                    <div class="van-sku__goods-price">
                        <span class="van-sku__price-num" style="float: right;">{{props.price}}</span>
                        <span class="van-sku__price-symbol" style="float: right;">￥</span>
                    </div>
                </van-cell>
            </template>
            <template slot="sku-messages" v-if="props.selectedSkuComb && props.selectedSkuComb.remaining" slot-scope="props">
                <van-cell-group>
                    <van-cell title="剩余次数" :value="props.selectedSkuComb.remaining" />
                </van-cell-group>
            </template>
            <template slot="sku-actions" slot-scope="props">
                <div class="van-sku-actions">
                    <span style="display:inline-block;line-height: 41px;min-width: 40px;color: #ee0a24;font-size: 15px;">合计:</span>
                    <div class="van-sku__goods-price" style="line-height: 40px;margin-right: 16px;">
                        <span class="van-sku__price-num">￥{{(props.price * props.selectedNum).toFixed(2) }}</span>
                    </div>
                    <!-- 直接触发 sku 内部事件，通过内部事件执行 onBuyClicked 回调 -->
                    <van-button square size="large" type="danger" @click="beforePay">
                        立即购买
                    </van-button>
                </div>
                <explore-service ref="exploreService" @confirmRule="props.skuEventBus.$emit('sku:buy')"></explore-service>
            </template>
        </van-sku>
        <phone-bind ref="phoneBind" @success="bindPhoneSuccess"></phone-bind>
    </div>
</template>

<script>
    import {Toast,Dialog,Notify} from 'vant';
    import wx from 'weixin-js-sdk';
    import PhoneBind from "../Common/Dialog/PhoneBind";
    import ExploreService from "../Common/Dialog/ExploreService";
    export default {
        name: "ExploreBuy",
        components:{
            PhoneBind,
            ExploreService,
        },
        data() {
            return {
                show: false,
                sku: {
                    // 所有sku规格类目与其值的从属关系，比如商品有颜色和尺码两大类规格，颜色下面又有红色和蓝色两个规格值。
                    // 可以理解为一个商品可以有多个规格类目，一个规格类目下可以有多个规格值。
                    tree: [
                        {
                            k: '服务类型', // skuKeyName：规格类目名称
                            v: [{id: '1', name: '',}],
                            k_s: 's1' // skuKeyStr：sku 组合列表（下方 list）中当前类目对应的 key 值，value 值会是从属于当前类目的一个规格值 id
                        }
                    ],
                    // 所有 sku 的组合列表，比如红色、M 码为一个 sku 组合，红色、S 码为另一个组合
                    list: [

                    ],
                    price: '0.00', // 默认价格（单位元）
                    collection_id: 1, // 无规格商品 skuId 取 collection_id，否则取所选 sku 组合对应的 id
                    none_sku: false, // 是否无规格商品
                },
                goods: {
                    // 商品标题
                    title: '测试商品',
                },
                initialSku: { // 初始选中对象
                    s1: 1,
                    selectedNum: 1,// 初始选中数量
                    selectedProp: {1: [1]}// 初始选中的商品属性
                },
                verified_phone:false,
                verified_shop:false,
                shops:false,
                assets:[
                    require('../../assets/img/explore/buy_start.png'),
                    require('../../assets/img/explore/buy_title.png'),
                    require('../../assets/img/explore/buy1.png'),
                    require('../../assets/img/explore/buy2.png'),
                    require('../../assets/img/explore/buy3.png'),
                    require('../../assets/img/explore/buy4.png'),
                ],
            }
        },
        mounted() {
            this.loadData();
        },
        methods: {
            loadData(){
                this.post('/api/client/shop/explore/buy/list').then(result=>{
                    if(result.code === 0){
                        this.verified_phone = result.data.verified_phone;
                        this.verified_shop = result.data.verified_shop;
                        this.shops = result.data.shops;
                        this.sku.tree[0].v = result.data.explore_channels_buy;
                        this.sku.list = result.data.explore_channels_list;
                    }
                });
            },
            buy(){
                Dialog.alert({
                    title:'提示',
                    message:'暂时不允许购买',
                });
                return;
                // 检查当前用户是否绑定了手机号
                if(!this.verified_phone){
                    this.$refs.phoneBind.init();
                    return;
                }
                if(this.shops){
                    this.show = true;
                    return;
                }
                Dialog.alert({
                    title: '提示',
                    message: '您还未绑定店铺信息，是否前往绑定？',
                    showCancelButton: true,
                }).then(() => {
                    this.$router.push('/shop');
                });
            },
            bindPhoneSuccess(){
                this.verified_phone = true;
                Notify('手机号绑定成功');
                this.buy();
            },
            onBuyClicked(sku) {
                let that = this;
                console.log(sku);
                this.post('/api/client/shop/explore/buy',{
                    num:sku.selectedNum,
                    channel_id:sku.selectedSkuComb.id,
                    price:sku.selectedSkuComb.price,
                }).then(function(result){
                    if(result.code === 0){
                        // 订单生成成功，支付
                        wx.chooseWXPay({
                            timestamp: result.data.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                            nonceStr: result.data.nonceStr, // 支付签名随机串，不长于 32 位
                            package: result.data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                            signType: result.data.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                            paySign: result.data.paySign, // 支付签名
                            success: function (res) { // 支付成功后的回调函数
                                that.show = false;
                                Dialog.confirm({
                                    title: '购买成功',
                                    message: '您已购买成功，是否立即使用或继续购买？',
                                    confirmButtonText:'立即使用',
                                    cancelButtonText:'返回',
                                }).then(() => {
                                    // 立即使用
                                    that.$router.push('/explore/apply');
                                });
                            }
                        });
                    } else {
                        Toast.fail(result.msg);
                    }
                })
            },
            beforePay(){
                if (this.verified_shop) {
                    this.$refs.exploreService.init(1);
                } else {
                    this.$dialog.alert({
                        title: '提示',
                        message: '很抱歉，您还没有资格审核通过的店铺,不建议您直接购买。',
                    });
                }
            },
        }
    }
</script>

<style scoped>
    .advantage{
        display: flex;
        font-size: 16px;
        text-align: center;
        flex-direction: column;
    }
    .advantage>div{
        display: flex;
        margin-bottom: 15px;
    }
    .advantage .van-image{
        width: 40px;
        margin-left: 15px;
        flex: 0 0 auto;
        display: inline-block;
    }
    .advantage>div>p{
        font-weight: bold;
        font-size: 15px;
        display: inline-block;
        margin: auto 10px;
        text-align: left;
    }
    .van-goods-action{
        background:none;
    }
</style>